<template>
    <div class="content" v-if="!hasLogin">
        <div class="mask"></div>
        <div class="box">
            <div class="box-top">
                <span class="title">用户登录</span>
            </div>
            <div class="box-content">
                <el-form label-position="left" label-width="80px">
                    <el-form-item label="用户名:">
                        <el-input v-model="userName" size="small" clearable maxlength="40"></el-input>
                    </el-form-item>
                    <el-form-item label="密码:">
                        <el-input type="password" v-model="pwd" size="small"></el-input>
                    </el-form-item>
                </el-form>
                <div class="remember">
                    <el-checkbox v-model="rememberMe" size="medium">记住密码</el-checkbox>
                </div>
            </div>
            <div class="box-footer">
                <div class="login" @click="toLogin">
                    <span>登录</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Message } from 'element-ui';
import { encode, decode } from '@/utils/sswgUtil';

export default {
    inject: ['app'],
    data() {
        return {
            userName: '',   // 默认记住用户名
            pwd: '',
            rememberMe: false,
            k: 'AAAABBBBCCCCDDDD', // AES密钥
        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        }
    },
    mounted() {
        let remember = localStorage.getItem('login_remember_me') || '0'
        if(remember==1){
            // 填充勾选
            this.rememberMe = true
            // 填充账号
            this.userName = localStorage.getItem('login_user_name')
            // 解密并填充密码
            let str = localStorage.getItem('login_pwd')
            this.pwd = decode(str, this.k)
        }
    },
    methods: {

        // 登录
        toLogin(){
            if(this.userName==''){
                Message.warning('请输入用户名')
                return
            }
            if(this.pwd==''){
                Message.warning('请输入密码')
                return
            }
            let p = {
                username: this.userName,
                password: this.pwd,
                isRememberme: 0
            }
            // 第三个参数表示不需要验证登录附加token
            this.sswgPost('/user/signin', p, false).then(res => {
                console.log(res)
                if(res.code==200){
                    localStorage.setItem('vms_token', res.data.token)
                    localStorage.setItem('login_agent_id', res.data.agentId)
                    localStorage.setItem('login_user_name', res.data.username)

                    // 调用爷爷组件查询配置信息
                    this.app.app.querySettingInfo()

                    // 更新登录
                    this.$store.commit('updateLogin', {loginUserName: res.data.username})
                    if(this.rememberMe){
                        localStorage.setItem('login_remember_me', '1')
                        localStorage.setItem('login_pwd', encode(this.pwd, this.k))
                    }else{
                        localStorage.removeItem('login_remember_me')
                        localStorage.removeItem('login_pwd')
                        this.pwd = ''
                    }
                }else{
                    Message.warning(res.message)
                }
            })
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        width: 100%;
        height: 100%; 
        position: fixed; 
        z-index: 2000; 
        .mask {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 190;
            opacity: 0.8;
            background-color: #74b1d1;
        }
        .box {
            position: fixed;
            left: 50%;
            top: 45%;
            width: 280px;
            background-color: #0078d7;
            border-radius: 5px;
            transform: translate(-50%,-50%);
            border: 1px solid #5d6359;
            z-index: 21000000;
            color: #ffffff;
            .box-top {
                height: 36px;
                text-align: center;
                font-size: 16px;
                padding-top: 8px;
                border-bottom: 1px solid #509bd6;
            }
            .box-content {
                padding: 0px 20px;
                margin-top: 15px;
                .remember {
                    display: flex;
                    justify-content: flex-end;
                }
            }
            .box-footer {
                display: flex;
                justify-content: center;
                margin-top: 15px;
                margin-bottom: 20px;
                .login {
                    width: 70%;
                    padding: 5px 0;
                    border-radius: 4px;
                    text-align: center;
                    color: #000000;
                    cursor: pointer;
                    background: linear-gradient(to right, #93c9f1, #c8e5fa);
                    user-select: none;
                }
            } 
        }
    }
</style>

<style scoped>
    .box-content >>> .el-form-item__label {
        color: #ffffff !important;
    }

    .box-content >>> .el-form-item {
        margin-bottom: 15px !important;
    }

    .box-content >>> .el-checkbox__label {
        color: #ffffff;
    }

    .box-content >>> .el-checkbox__input.is-checked + .el-checkbox__label {
        color: #ffffff;
    }

    .box-content >>> input.el-input__inner {
        font-size: 16px;
        font-weight: bold;
    }
</style>