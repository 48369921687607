import request from '@/sswg/request'
import store from '@/store';

export function sswgPost(url, data, authLogin, contentType) {
    return request({
        url: url,
        method: 'post',
        data: data,
        authLogin: authLogin,
        contentType: contentType
    }).catch(error=>{
        console.log('123出错了')
        // console.log(err)
        let { message } = error;
        if (message == "Network Error") {
            console.log('准备退出登录')
            store.commit('logout')
        }
    })
}

// export function sswgPost(url, data, authLogin, contentType) {
//     return request({
//         url: url,
//         method: 'post',
//         data: data,
//         authLogin: authLogin,
//         contentType: contentType
//     })
// }

export function sswgGet(url, q, authLogin, contentType) {
    return request({
        url: url,
        method: 'get',
        params: q,
        authLogin: authLogin,
        contentType: contentType
    }).catch(error=>{
        console.log('sswgGet出错了')
        // console.log(err)
        let { message } = error;
        if (message == "Network Error") {
            console.log('准备退出登录 sswgGet')
            store.commit('logout')
        }
    })
}

export function sswgDel(url, q, authLogin, contentType) {
    return request({
        url: url,
        method: 'delete',
        params: q,
        authLogin: authLogin,
        contentType: contentType
    })
}