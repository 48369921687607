<template>
    <div class="content">
        <AisDetail ref="aisDetailRef"></AisDetail>
    </div>
</template>

<script>
import AisDetail from './AisDetail.vue';

//引入自定义目标图层
import CustomTargetAis from '../../../public/hd/CustomTargetAis'
import { llPointToENPoint } from '@/utils'

export default {
    components: {
        AisDetail
    },
    inject: ['instanceProvide', 'app'],
    // 本组件提供给子组件
    provide() {
        return {
            parent: this,
        }
    },
    data() {
        return {
            dataList: [],

            aisInterval: null,

            currShipIndex: null,
            // 当前mmsi 控制选中状态不被定时任务刷新
            currMmsi: '',
            lastSelectedTarget: null,
        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
        showAisList(){
            return this.$store.state.showAisList
        }
    },
    watch: {
        hasLogin(newVal, oldVal){
            if(newVal){
                // this.queryShipList(1)
                // this.connectWebSocket()         // 登录成功之后 创建连接
                // this.createWarnWebSocket()      // 登录成功之后 创建告警websocket连接

                // // 刷新设置信息
                // this.app.querySettingInfo()
            }else{
                // 退出登录之后 关闭定时器
                if(this.aisInterval){
                    clearInterval(this.aisInterval)
                }
            }
        },
        showAisList(newVal, oldVal){
            console.log(newVal)
            if(newVal){
                this.openAis()
            }else{
                this.closeAis()
            }
        }
    },
    methods: {

        /**
         * 查询AIS船舶列表
         */
         queryAisShipList(){
            console.log('ais '+new Date().getSeconds())
            let p = {
                minLon: 113.5,
                maxLon: 114.5,
                minLat: 22,
                maxLat: 23
            }
            this.sswgGet('/ais/getShipAroundListByRange', p, true, 'json').then(res => {
                if(res){
                    this.dataList = res.data
                    this.drawTarget()
                }
            })
        },

        // 添加船舶到海图
        async drawTarget() {
            // console.log(this.instanceProvide.aisLayer.items())
            // 清除原来的物标，刷新页面时为null
            if(this.instanceProvide.aisLayer){ 
                this.instanceProvide.aisLayer.clearItems()
            }
            // console.log(this.instanceProvide.aisLayer.items())
            this.lastSelectedTarget = null

            let currTimes = Math.floor(new Date().getTime()/1000) 
            let fillColor = '#FFFF66'

            let shipIdIndex = 0 // 船舶索引
            for(let i=0; i<this.dataList.length; i++){
                let ship = this.dataList[i]
                if(ship.lon){
                    // console.log('我是第+'+i)
                    // console.log(ship)
                    // 记录船舶所在的索引
                    // this.app.shipIdData['aisShipId'+ship.id] = shipIdIndex
                    
                    // 如果上次更新时间间隔大于30分钟，则标记灰色
                    if(currTimes-ship.timestamp>1800){
                        fillColor = '#a8934d'
                    }else{
                        fillColor = '#FFFF66'
                    }

                    const point = new CustomTargetAis({
                        center: llPointToENPoint(ship.lon, ship.lat),
                        text: ship.mmsi,    // ship.mmsi
                        shipScale: 11,                  //三角船显示层级
                        titleScale: 8,                  //船名显示层级
                        cog: parseFloat(ship.cog),      //航向
                        sog: 0,                         //航速
                        hdg: 10,                        //船艏向
                        fillType: 1,                    //船只填充类型
                        fillColor: fillColor,           //船只填充颜色
                        strokeColor: '#000000',         //船只边框颜色
                        strokeType: 1,                  //船只边框类型
                        strokeWeight: 1,                //船只边框宽度
                        textColor: '#000000',            //文本颜色
                        labelFillColor: '#d4eaee',      //Label填充颜色 #d4eaee
                        labelFillType: 1,               //Label填充类型
                        fontSize: 10,                   //文字大小
                    })

                    
                    // point.setEnableMouseTracking(true)      // 使hover效果生效
                    this.instanceProvide.aisLayer.addItem(point)

                    //pressed 鼠标按下
                    point.on('pressed', (item, event) => {
                        console.log(item)
                        // item.setZIndex(10000)
                        //pressed 鼠标抬起
                        point.on('release', (item, event) => {
                            // 如果有上一个，则清除上一个的选择状态
                            if (this.lastSelectedTarget) {
                                this.lastSelectedTarget.setSelected(false)
                            }
                            item.setSelected(true)
                            this.lastSelectedTarget = item
                            item.setOptions({
                                // fillColor: '#FFFF66',
                                fillType: 1,
                                strokeColor: '#ff0000'
                            })
                        })

                        // 选中船舶
                        this.currShipIndex = item.shipIndex
                        this.queryShipAis(item.mmsi)
                    })

                    point.on('hoverenter', (item, event) => {
                        // console.log('ais hoverenter');
                        item.setOptions({
                            // fillColor: '#ffff00',
                            fillType: 1,
                            strokeColor: '#ff0000'
                        })
                    })

                    point.on('hoverleave', (item, event) => {
                        item.setOptions({
                            // fillColor: '#2ECC71',
                            fillType: 1,
                            strokeColor: '#000000'
                        })
                    })


                    // point.setShipIdAndMerchantId(ship.id, ship.outletsId)   // 补充参数 船ID和店铺ID
                    point.setShipIndex(shipIdIndex)

                    point.setShipMmsi(ship.mmsi)
                    if(this.currMmsi==ship.mmsi && this.$refs.aisDetailRef && this.$refs.aisDetailRef.show==true){
                        point.setSelected(true)
                        this.lastSelectedTarget = point
                    }


                    // this.targetList.push(point)
                    shipIdIndex++
                }
            }
        },

        // 清除选择
        clearSelected(){
            console.log('清除选择==')
            if (this.lastSelectedTarget) {
                this.lastSelectedTarget.setSelected(false)
                this.lastSelectedTarget = null
            }
            if(this.currShipIndex){
                // this.instanceProvide.aisLayer.items()[this.currShipIndex].setSelected(false)
                this.instanceProvide.aisLayer.items()[this.currShipIndex].setOptions({
                    fillType: 1,
                    strokeColor: '#000000'
                })
                this.currShipIndex = null
            }
        },

        queryShipAis(mmsi){
            this.currMmsi = mmsi
            let p = {
                mmsi: mmsi,
                lon: 22,
                lat: 23
            }
            this.sswgGet('/ais/getShipAroundByMmsi', p, true, 'json').then(res => {
                console.log(res)
                if(res && this.$refs.aisDetailRef){
                    this.$refs.aisDetailRef.setShow()
                    this.$refs.aisDetailRef.shipInfo = res.data
                }
            })
        },

        openAis(){
            if(this.aisInterval){
                clearInterval(this.aisInterval)
            }
            this.aisInterval = setInterval(()=>{
                this.queryAisShipList()
            }, 10000)
            this.queryAisShipList() // 执行一次
        },

        closeAis(){
            console.log('清空ais')
            if(this.aisInterval){
                clearInterval(this.aisInterval)
            }
            // 清除物标
            if(this.instanceProvide.aisLayer){ 
                this.instanceProvide.aisLayer.clearItems()
            }
            this.$refs.aisDetailRef.close()
        }
    }
}
</script>