<template>
    <div class="content" v-if="show">
        <div class="v1">
            <ul>
                <li>
                    <label>中文船名</label>
                    <span>{{ ship.name }}</span>
                </li>
                <li>
                    <label>英文船名</label>
                    <span>{{ ship.enName }}</span>
                </li>
                <li>
                    <label>船舶类型</label>
                    <span>{{ ship.shipTypeName }}</span>
                </li>
                <li>
                    <label>航行状态</label>
                    <span>{{ ship.statusName }}</span>
                </li>
                <li>
                    <label>船籍</label>
                    <span>{{ ship.country }}</span>
                </li>
                <li>
                    <label>船长</label>
                    <span>{{ ship.shipLength }}</span>
                </li>
                <li>
                    <label>船宽</label>
                    <span>{{ ship.shipWidth }}</span>
                </li>
                <li>
                    <label>吃水</label>
                    <span>{{ ship.depthOfWarter }}</span>
                </li>
                <li>
                    <label>旅客人数</label>
                    <span>{{ ship.numOfGuests }}</span>
                </li>
                <li>
                    <label>是否定位</label>
                    <span>{{ ship.isPositioning }}</span>
                </li>
                <li>
                    <label>设备ID</label>
                    <span>{{ ship.positionDeviceId }}</span>
                </li>
                <li>
                    <label>设备类型</label>
                    <span>{{ ship.positionDeviceType }}</span>
                </li>
            </ul>
            <ul class="u2">
                <li>
                    <label>呼号</label>
                    <span class="w80">{{ ship.callNum }}</span>
                </li>
                <li>
                    <label>MMSI</label>
                    <span class="w80">{{ ship.mmsi }}</span>
                </li>
                <li>
                    <label>IMO</label>
                    <span class="w80">{{ ship.imo }}</span>
                </li>
                <li>
                    <label>艏向</label>
                    <span>{{ ship.direction }}</span>
                </li>
                <li>
                    <label>航向</label>
                    <span>{{ ship.course }}</span>
                </li>
                <li>
                    <label>航速</label>
                    <span>{{ ship.speed }}</span>
                </li>
                <li>
                    <label>纬度</label>
                    <span>{{ ship.lat }}</span>
                </li>
                <li>
                    <label>经度</label>
                    <span>{{ ship.lon }}</span>
                </li>
                <li>
                    <label>高度</label>
                    <span>{{ ship.height }}</span>
                </li>
                <li>
                    <label>目的地</label>
                    <span>{{ ship.destination }}</span>
                </li>
                <li>
                    <label>预到时间</label>
                    <span>{{ ship.expectedArrivalTime }}</span>
                </li>
                <li>
                    <label>更新时间</label>
                    <span>{{ ship.updateTime }}</span>
                </li>
            </ul>
            
        </div>
        <!--船舶头像 图片列表-->
        <div class="avatar" @click="clickAvatar">
            <el-image :src="$baseResourceUrl+ targetItem.photoLocation[0]" fit="scale-down" v-if="targetItem.photoLocation && targetItem.photoLocation.length>0"></el-image>
        </div>
        <span class="close-btn" @click="close">X</span>

        <div class="v2">
            <ul>
                <li class="aim" v-bind:class="[aimSelected?'aim-on':'']" @click="clickAim()" title="显示居中"></li>
                <li class="track" v-bind:class="[trackSelected?'track-on':'']" @click="clickTrack()" title="历史轨迹"></li>
                <li class="camera" v-bind:class="[cameraSelected?'camera-on':'']" @click="clickCamera()" title="查看视频"></li>
                <!-- <li @click="clickKeleTest()" style="color: orange;">测试</li> -->

                <!-- <li>
                    <img alt="历史轨迹" src="../../assets/img/ship/camera.svg" style="width: 24px; height: 24px;"/>
                </li> -->
                
            </ul>
        </div>

        <!--弹框 [历史轨迹]-->
        <div class="modal" v-if="showModal">
            <div class="modal-content" :style="{'width': modalWidth+'px'}">
                <div class="box-top">
                    <span class="box-title">{{ modalTitle }}</span>
                    <div class="top-btn">
                        <img alt src="../../assets/img/common/close.png"  @click="closeMoal()"/>
                    </div>
                </div>
                <div class="box-content" v-if="currModal=='track'">
                    <el-form :model="track.q" size="small" inline label-width="106px" label-position="right">
                        <el-form-item label="起始时间">
                            <el-date-picker v-model="track.q.beginDateTime" type="datetime" placeholder="起始时间" value-format="yyyy-MM-dd HH:mm:ss" default-time="00:00:00" style="width: 205px;" :picker-options="trackPickerOptions"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="截止时间">
                            <el-date-picker v-model="track.q.endDateTime" type="datetime" placeholder="截止时间" value-format="yyyy-MM-dd HH:mm:ss" default-time="23:59:59" style="width: 205px" :picker-options="trackPickerOptions"></el-date-picker>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="box-footer">
                    <button @click="sure">确认</button>
                    <button @click="closeMoal">取消</button>
                </div>
            </div>
        </div>

        <!--弹框-->
        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" class="sswg-modal">
            <!--船舶头像-->
            <el-carousel v-if="currModal=='avatar'" :interval="3000" arrow="always" style="width: 500px; height: 300px;">
                <el-carousel-item v-for="(item, index) in targetItem.photoLocation" :key="index">
                    <el-image :src="$baseResourceUrl+item" fit="scale-down" style=""></el-image>
                </el-carousel-item>
            </el-carousel>
            <!--查看视频-->
            <div v-else-if="currModal=='camera'" style="" class="camera">
                <div class="camera-channal-list" >
                    <ul>
                        <template v-for="(item) in channelList">
                            <li @click="openChannal(item.id)" :title="item.name">{{ item.name }}</li>
                        </template>
                    </ul>
                    <!-- <el-checkbox-group v-model="checkedChannelList" @change="handleCheckedChange">
                        <el-checkbox v-for="(item, index) in channelList" :label="item.name" :key="index">{{item.name}}</el-checkbox>
                    </el-checkbox-group> -->
                </div>
                <div class="camera-content">
                    <div id="ws-real-player" width="100%" height="500" v-if="mediaType==0"></div>
                    <video autoplay id="myVideoFlv" width="590" v-else-if="mediaType==1" ></video>
                </div>
            </div>
        </SswgModal>

    </div>
</template>

<script>
import { Message } from 'element-ui';
import { lonToDegreeMinString, latToDegreeMinString, llPointToENPoint } from '@/utils';
import { TrackItem, TrackPoint } from '../../../public/hd/chartbox';
import flvjs from 'flv.js/dist/flv.min.js';
import { iccWsURL, currEnv } from '@/sswg/config'

// 大华播放器
// import '../../../public/static/dhPro/WSPlayer/player.css'
// import '../../../public/static/dhPro/WSPlayer/window.division.css'
// import PlaySDKInterface from '../../../public/static/dhPro/WSPlayer/PlaySDKInterface'
// import WSPlayer from '../../../public/static/dhPro/WSPlayer/WSPlayer'

import PlayerManager from '@/utils/icc/PlayerManager';

const lebalTargetItem = (item) => {
    return {
        name: item.name,
        enName: item.enname || '--',
        shipTypeName: item.shipTypeName || '--',
        statusName: item.status==1 ? '航行' : (item.status==2?'移泊':'靠泊'),
        country: item.country || '--',
        shipLength: item.shipLength ? `${item.shipLength}m` : '--',
        shipWidth: item.shipWidth ? `${item.shipWidth}m` : '--',
        depthOfWarter: item.depthOfWarter ? `${item.depthOfWarter}m` : '--',
        numOfGuests: item.numOfGuests || '--',
        isPositioning: item.isPositioning==true ? '是' : '否',
        positionDeviceId: item.positionDeviceId || '--',
        positionDeviceType: item.positionDeviceType || '--',
        
        callNum: (item.callNum==null || item.callNum=='0') ? '--' : item.callNum,
        mmsi: item.mmsi || '--',
        imo: item.imo || '--',
        direction: item.direction || '--',
        course: item.course==null ? '--' : item.course+'°',
        speed: item.speed==null ? '--' : item.speed+'Kn',
        lat: item.lat==null ? '--' : latToDegreeMinString(item.lat, 4),
        lon: item.lot==null ? '--' : lonToDegreeMinString(item.lot, 4),
        height: item.height ? `${item.height}m` : '--',
        destination: item.destination || '--',
        expectedArrivalTime: item.expectedArrivalTime || '--',
        updateTime: item.updateTime || '--',
    }
}

export default {
    props: {
        targetItem: {
            type: Object,
            default: () => {}
        }
    },
    inject: ['instanceProvide', 'app'],
    data() {
        return {
            aimSelected: false,         // 显示居中
            trackSelected: false,       // 历史轨迹
            cameraSelected: false,

            showModal: false,           // 显示modal
            currModal: '',              // 当前modal avatar:头像 track:历史轨迹 ais:AIS warn:声光报警
            modalTitle: '',
            modalWidth: 370,            // 当前modal宽度 px
            // 轨迹
            track: {
                q: {
                    beginDateTime: this.parseTime(new Date(), '{y}-{m}-{d} 00:00:00'),
                    endDateTime: this.parseTime(new Date())
                }
            },
            // 控制轨迹不能选择未来的日期
            trackPickerOptions: {
                disabledDate(time){
                    return time.getTime() > Date.now()
                }
            },

            modalVisible: false,
            avatarList: [],

            flvPlayer: null,
            webrtcUrl: '',

            // 通道列表
            channelList: [],
            mediaType: 0,       // 媒体类型 0:使用ICC播放器 1:使用DSS播放器

            // 大华ICC实时播放器对象
            realplayer: null,
            playIndex: 0,       // 当前窗口


            checkedChannelList: []
        }
    },
    computed: {
        show(){
            return this.$store.state.showShipDetail
        },
        ship(){
            // targetItem船舶变动，则重置
            console.log('》》》》targetItem船舶变动了 id='+this.targetItem.name)
            // this.reset()
            return lebalTargetItem(this.targetItem)
        },
    },
    methods: {

        handleCheckedChange(value){
            console.log(value)
        },


        close(){
            this.$store.commit('updateShowShipDetail', false)
        },

        clickKeleTest(){
            console.log('点击了测试按钮')
            // this.webrtcUrl = 'http://172.16.31.209:9977/resource/video/movie.flv'
            // this.currModal = 'camera'
            // this.modalTitle = '查看视频'
            // this.modalVisible = true

        },

        // 初始化ICC播放器
        initializePlayer() {
            let realPlayer = new PlayerManager({
                el: 'ws-real-player', /** 实时预览容器id，创建多个播放器，传入不同的容器id即可 **/
                type: 'real', /** real - 实时预览  record - 录像回放 **/
                maxNum: 4,  /** 一个播放器上限能播放的路数，可根据实际情况设置，支持 1 4 9 16 25 **/
                num: 1,   /** 初始化，页面显示的路数 **/
                showControl: true, /** 是否显示工具栏，默认显示 **/
                showIcons: { // 自定义按钮，需要的请配置true, 不需要的按钮请配置false，所有的按钮属性都要写上
                    streamChangeSelect: true, // 主辅码流切换
                    talkIcon: false, // 对讲功能按钮
                    localRecordIcon: false, // 录制视频功能按钮
                    audioIcon: true, // 开启关闭声音按钮
                    snapshotIcon: true, // 抓图按钮
                    closeIcon: true, // 关闭视频按钮
                },
                openIvs: true, // true-开启智能帧/规则线/目标框, false-不显示
                showRecordProgressBar: true, // 录像回放时，录像的进度条是否需要
                useH265MSE: true, // true-表示硬解  false-软解 默认不传该字段
                prefixUrl: 'static/dhPro',
                receiveMessageFromWSPlayer: (methods, data, err) => { /* 回调函数，可以在以下回调函数里面做监听 */
                    switch(methods) {
                        case 'initializationCompleted':
                            console.log("初始化完成")
                            // 初始化完成，可调用播放方法（适用于动态加载解码库）
                            // 若回调未触发时就使用实时预览/录像回放，则无法播放。
                            // 此时我们可以调用一个
                            /**
                            this.realPlayer.playRealVideo({
                                channelList: [{
                                    id: '100000$1$0$0', // {String} 通道编码 -- 用于预览，必填
                                    deviceCode: deviceCode, // {String} 设备编码 -- 用于对讲，对讲必填，无对讲功能可不填
                                    deviceType: deviceType, // {String} 设备类型 -- 用于对讲，对讲必填，无对讲功能可不填
                                    channelSeq: channelSeq, // {String|Number} 通道序号 -- 用于对讲，对讲必填，无对讲功能可不填
                                    cameraType: cameraType, // {String|Number} 摄像头类型 -- 用于云台，云台必填，无云台功能可不填
                                    capability: capability, // {String} 能力集 -- 用于云台，选填
                                }],
                                streamType: 1, // {Number} 码流类型 1-主码流 2-辅码流
                                windowIndex: 0 // {Number} 播放窗口序号（从0开始）
                            })
                                 */
                            break;
                        case "realSuccess": // 实时预览成功
                            console.log("实时预览成功",)
                            break;
                        case "realError": // 实时预览失败
                            console.log("实时预览失败")
                            break;
                        case "talkError": // 对讲失败
                            console.log("对讲失败");
                            break;
                        case 'selectWindowChanged': // 选中的窗口发生改变
                            console.log(data, "返回窗口信息")
                            this.playIndex = data.playIndex
                            break;
                        case 'windowNumChanged': // 播放器显示的路数发生改变
                            console.log(data, "返回显示的窗口数量")
                            break;
                        case 'closeVideo': // 视频关闭回调
                            // 点击关闭按钮引发的视频关闭进行提示
                            // 切换视频引发的视频关闭不进行提示
                            if(!data.changeVideoFlag) {
                                console.log(`窗口${data.selectIndex}的视频已关闭`)
                            }
                            break;
                        case 'statusChanged': // 视频状态发生改变
            
                            break;
                        case 'errorInfo': // 错误信息汇总
                            console.log(data, "可打印查看错误消息");
                    }
                }
            })

            // ICC播放器对象
            if(realPlayer){
                this.realplayer = realPlayer
            }else{
                console.log('realPlayer为空')
            }
        },

        // 开始播放
        startReal(channelId, rtspURL) {
            if (!this.realplayer) {
                console.log("不能播放，请先初始化！！！！！");
                return;
            }
            this.realplayer.realByUrl({
                rtspURL: rtspURL,
                wsURL: iccWsURL,
                channelId: channelId, //必传，通道id
                streamType: 1, // 1-主码流  2-辅码流 (可不传，默认主码流)
                selectIndex: this.playIndex, // 播放窗口 0 1 2 3
                playerAdapter: 'stretching', //'窗口样式："selfAdaption" 自适应 | "stretching" 拉伸',
            });
        },

        // 初始化DSS播放器(FLV播放器)
        webrtcPlay(){
            let resourceType = 'flv'
            if(this.webrtcUrl.indexOf('.mp4')>0){
                resourceType = 'mp4'
            }

            // http 转 https前缀 (后端传的接口数据问题)
            this.webrtcUrl = this.webrtcUrl.replace('http:', 'https:')

            if(flvjs.isSupported()){
                if(this.flvPlayer){
                    // 存在播放器对象，开始暂时并销毁
                    this.flvPlayer.pause()
                    this.flvPlayer.unload()
                    this.flvPlayer.detachMediaElement()
                    this.flvPlayer.destroy()
                }
                this.flvPlayer = flvjs.createPlayer(
                    {
                        type: resourceType,
                        url: this.webrtcUrl
                    },
                    {
                        cors: true,
                        enableStashBuffer: false // 当带音频播放时,config部分配置项尽量采取默认状态,否则过分优化会造成卡死
                    }
                )
                this.flvPlayer.attachMediaElement(document.getElementById('myVideoFlv'))
                this.flvPlayer.load()
                this.flvPlayer.play()
                // 报错重连
                // this.flvPlayer.on(flvjs.Events.ERROR, (errType, errDetail) => {
                //     console.log('errType:', errType)
                //     console.log('errorDetail:', errDetail)
                //     if(this.flvPlayer){
                //         this.destoryVideo()
                //         this.webrtcPlay()
                //     }
                // })
            }else{
                console.log('当前浏览器不支持FLV播放器')
            }
        },

        destoryVideo() {
            if (this.flvPlayer) {
                this.flvPlayer.pause()
                this.flvPlayer.unload()
                this.flvPlayer.detachMediaElement()
                this.flvPlayer.destroy()
                this.flvPlayer = null
            }
        },

        clickAvatar(){
            if(this.targetItem.photoLocation && this.targetItem.photoLocation.length>0){
                this.currModal = 'avatar'
                this.modalTitle = '船舶图片列表'
                this.modalVisible = true
            }else{
                this.$message.warning('未设置船舶图片')
            }
        },

        // reset(){
        //     this.aimSelected = false
        // },

        // 供外部调用
        updateTrackSelected(){
            this.trackSelected = false
        },

        /**
         * 根据websocket的数据更新船舶信息(供外部调用)
         * @param lot 经度
         * @param lat 纬度
         * @param cog 航向
         * @param sog 航速
         * @param reportTime 更新时间
         */
        updateShipInfoByWebsocketData(lot, lat, cog, sog, reportTime){
            this.targetItem.lot = lot
            this.targetItem.lat = lat
            this.targetItem.course = cog
            this.targetItem.speed = sog
            this.targetItem.updateTime = reportTime
        },

        // 显示居中
        clickAim(){
            // this.aimSelected = true      // 暂时不修改选中之后的样式
            console.log(this.targetItem)
            if(this.targetItem.lot){
                const center = llPointToENPoint(this.targetItem.lot, this.targetItem.lat)
                this.instanceProvide.map.setCenter(center)
            }else{
                console.log('无经纬度坐标')
            }
        },

        // 历史轨迹
        clickTrack(){
            this.trackSelected = true
            this.showModal = true
            this.currModal = 'track'
            this.modalTitle = '历史轨迹'
            this.modalWidth = 370
            this.$store.commit('updateShowBySrc', {src: 'track', val: 1})

            // 每次打开都是当前时间
            this.track.q.endDateTime = this.parseTime(new Date())
        },

        // 查看视频 查询通道列表
        clickCamera(){
            console.log('点击了查看视频')
            this.currModal = 'camera'
            this.modalTitle = '查看视频'
            this.modalVisible = true

            this.mediaType = this.targetItem.mediaType
            console.log('当前mediaType='+this.mediaType+ '【0:使用ICC播放器 1:使用DSS播放器】' )

            // 获取视频设备通道列表
            let outId = this.targetItem.outletsId
            this.sswgPost('/media/fetchDeviceChannelList', {outletsId: outId}).then(res => {
                console.log('通道列表>>>>')
                console.log(res)
                if(res.code==200){
                    this.channelList = res.data

                    // 模拟测试 (如果是开发环境)
                    if(this.channelList.length==0 && currEnv=='dev'){
                        let a1 = {id: '1000120$1$0$0', name: '鹏星20轮-驾驶室左'}
                        let a2 = {id: '1000120$1$0$9', name: '鹏星20轮-船尾主甲板和接口还是看对方'}
                        this.channelList.push(a1)
                        this.channelList.push(a2)
                        for(let i=0; i<30; i++){
                            let a = {id: '1000120$1$0$0', name: '鹏星20轮-驾驶室左'+(i+1)}
                            this.channelList.push(a)
                        }
                    }

                }
            })

            // 0:初始化ICC播放器 1:初始化DSS播放器（FLV）
            if(this.mediaType==0){
                this.realplayer = null
                this.$nextTick(function(){
                    this.initializePlayer()
                })
            }else if(this.mediaType==1){
                // this.webrtcUrl = 'http://172.16.31.209:9977/resource/video/movie.flv'

            }
        },

        // 开启通道 并播放
        openChannal(channelId){

            // this.webrtcPlay()
            // return

            let outletsId = this.targetItem.outletsId
            this.sswgPost('/media/play', {outletsId: outletsId, channelId: channelId}).then(res => {
                console.log(res)
                if(res.code==200){
                    console.log('开启通道成功')
                    let rtspURL = res.data
                    if(this.mediaType==0){      // ICC播放器
                        this.startReal(channelId, rtspURL)
                    }else if(this.mediaType==1){ // DSS播放器
                        this.webrtcUrl = res.data
                        this.webrtcPlay()
                    }
                }else{
                    console.log('开启通道失败')
                    this.$message.warning('查看视频失败')
                }
            })
        },

        // 关闭modal
        closeMoal(){
            this.showModal = false
            if(this.currModal=='track'){
                this.trackSelected = false
            }
        },

        // 确认modal
        sure(){
            if(this.currModal=='track'){
                this.queryTrackData()
            }
            
        },

        // 查询轨迹数据
        queryTrackData(){
            console.log('查询轨迹数据')
            // console.log(this.targetItem)
            if(!this.track.q.beginDateTime){
                Message.warning('请选择起始时间')
                return
            }
            if(!this.track.q.endDateTime){
                Message.warning('请选择截止时间')
                return
            }
            let q = {
                startTime: this.track.q.beginDateTime,
                endTime: this.track.q.endDateTime,
                outletsId: this.targetItem.outletsId
            }

            this.sswgGet('/history/fetchOutletsHistoryLocations', q).then(res => {
                // console.log(res)
                let trackList = res.data.simpleData
                /**
                // 测试模拟数据
                trackList = [{
                    "lot": 117.412497,
                    "lat": 23.224353,
                    "voyageAngle": "232.58",
                    "voyageSpeed": "5.01",
                    "reportTime": "2024-10-28 10:00:06",
                    "avgSpeed": null,
                    "distanceKnob": 0.0081839046
                },
                {
                    "lot": 117.366230,
                    "lat": 23.088302,
                    "voyageAngle": "222.66",
                    "voyageSpeed": "4.82",
                    "reportTime": "2024-10-28 10:00:60",
                    "avgSpeed": null,
                    "distanceKnob": 0.0081839046
                }]
                if(this.targetItem.id%2==0){
                    trackList[1].lot = 117.414230
                }
                */
                
                if(trackList.length==0){
                    Message.warning('此时间段数据为空')
                }else{
                    this.handleTrackData(trackList)
                }
            })
        },

        /**
         * 处理轨迹数据
         * @param dataList 
        */
        handleTrackData(dataList){
            const trackItem = new TrackItem()
            for(let i=0; i<dataList.length; i++){
                let p = llPointToENPoint(dataList[i].lot, dataList[i].lat)
                let tp = new TrackPoint({
                    x: p[0],
                    y: p[1],
                    time: new Date(dataList[i].reportTime).getTime()/1000,
                    infos: [
                        dataList[i].reportTime
                    ]
                })
                trackItem.addPoint(tp)
            }
            // 轨迹id (一条轨迹的id)
            const trackId = 'r'+this.targetItem.id
            this.instanceProvide.targetTrackLayer.addTrackItem(trackId, trackItem)
            this.instanceProvide.targetTrackLayer.setSelected(trackId, true) // 默认选中
            // this.instanceProvide.targetTrackLayer.setShowStyle('k1', {
            //     pens: {
            //         "point": new Pen({ color: '#ffff00' }),
            //         "line": new Pen({ color: '#ffff00', width: 2 }),
            //         "text": new Pen({ color: '#0000ff' })
            //     }
            // })

            // 处理轨迹数据之后，需要执行的操作
            // 1、关闭弹框
            this.showModal = false
            // 2、更新历史轨迹列表数据
            // 2.1 过滤掉当前同轨迹id的轨迹
            let list = this.app.trackDataList
            list = list.filter(item => item.trackId!==trackId)
            // 2.2 增加当前轨迹 默认选中状态
            let a = {
                trackId: trackId,
                name: this.targetItem.name,
                selected: 1
            }
            list.push(a)
            // 2.3 更新列表数据
            this.app.trackDataList = list
        }

    },

}

</script>

<style lang="scss" scoped>
    .content {
        width: 445px;

        position: absolute;
        left: 10px;
        top: 10px;

        background-color: rgba(3, 76, 165, 0.81);
        border-radius: 5px;
        border: 2px solid #FFF;

        .avatar {
            width: 88px;
            height: 76px;
            background-color: #b9dffb;
            position: absolute;
            top: 5px;
            right: 5px;
            border-radius: 4px;
            cursor: pointer;
            display: flex;
            align-items: center;
        }

        .close-btn {
            position: absolute;
            top: -8px;
            right: -10px;
            background-color: #27619f;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
            color: #ebeaea;
            cursor: pointer;
            border: 2px solid #FFF;
            font-size: 12px;
        }

        .v1 {
            padding: 3px 5px;
            display: flex;
            justify-content: space-between;
            ul {
                li {
                    font-size: 13px;
                    color: #FFF;
                    display: flex;
                    align-items: center;
                    margin-top: 2px;
                    label {
                        width: 60px;
                    }
                    span {
                        width: 130px;
                        height: 22px;
                        line-height: 22px;
                        border: 1px solid #FFF;
                        border-radius: 4px;
                        text-indent: 5px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: block;
                    }
                }
            }

            .u2 {
                li {
                    span {
                        width: 170px;
                    }
                    .w80 {
                        width: 80px;
                    }
                }
            }
        }

        .v2 {
            margin: 2px 5px 0 5px;
            border-top: 1px solid #bbbaba;
            ul {
                display: flex;
                li {
                    // border: 1px solid red;
                    width: 40px;
                    height: 32px;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-position: center center;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .aim {
                    background-image: url('../../assets/img/ship/aim.png');
                }
                .aim:hover {
                    background-image: url('../../assets/img/ship/aim_on.png');
                }
                .aim-on {
                    background-image: url('../../assets/img/ship/aim_on.png');
                }

                .track {
                    background-image: url('../../assets/img/ship/track.svg');
                    background-size: 24px;
                }
                .track:hover {
                    background-image: url('../../assets/img/ship/track_on.svg');
                }
                .track-on {
                    background-image: url('../../assets/img/ship/track_on.svg');
                }

                .camera {
                    background-image: url('../../assets/img/ship/camera.svg');
                    background-size: 25px 25px;
                }
                .camera:hover {
                    background-image: url('../../assets/img/ship/camera_on.svg');
                }
                .camera-on {
                    background-image: url('../../assets/img/ship/camera_on.svg');
                }
            }
        }
            
    }

    .sswg-modal {

        .camera {
            display: flex;
            height: 500px;
            .camera-channal-list {
                // width: 160px;
                width: 190px;
                height: 100%;
                background-color: #283341;//
                // background-color: #FFF;
                padding-left: 5px;


                overflow-y: auto;
                overflow-x: hidden;

                

                ul {
                    width: 160px;
                    padding-top: 6px;
                    li {
                        cursor: pointer;
                        padding: 4px 4px;
                        white-space: nowrap; 
                        overflow: hidden; 
                        text-overflow: ellipsis;
                    }
                    li:hover {
                        background-color: #121a22;
                    }
                }
            }

            .camera-content {
                height: 100%;
                background-color: #1c2834;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

</style>

<style lang="scss" scoped>
    .modal {
        position: fixed;
        z-index: 210;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        background-color: rgba(117, 176, 208, 0.4);
        .modal-content {
            z-index: 220;
            background: linear-gradient(to right,#06397b,#015cc3);
            border: 1px solid #b2c8da;
            border-radius: 5px;
            width: 370px;   // 根据当前modal变动
            margin: 15% auto;
            color: #ffffff;
            .box-top {
                height: 32px;
                text-align: center;
                position: relative;
                border-bottom: 1px solid #b2c8da;
                .box-title {
                    line-height: 30px;
                    font-size: 14px;
                    user-select: none;
                }
                .top-btn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    img {
                        cursor: pointer;
                    }
                }
            }
            .box-content {
                padding: 20px 10px 10px 10px;
            }
            .box-footer {
                display: flex;
                justify-content: space-around;
                padding: 10px 0;
                button {
                    width: 30%;
                    height: 30px;
                    line-height: 30px;
                    border: 1px solid #000;
                    border-radius: 5px;
                    background: linear-gradient(to bottom, #8ec8f1, #b9dffb);
                    cursor: pointer;
                }
            }
        }
    }
    
</style>

<style scoped>
    .box-content >>> .el-form-item__label {
        color: #FFFFFF;
    }
    .box-content >>> input.el-input__inner {
        font-size: 15px;
        font-weight: bold;
    }

    .camera-channal-list >>> .el-checkbox {
        color: #FFFFFF;
    }
    .camera-channal-list >>> .el-checkbox__label {
        line-height: 22px;
        /* white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; */
    }
</style>