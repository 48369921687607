<template>
    <div class="content" v-if="show">
        <div class="mask"></div>
        <div class="box">
            <div class="box-top">
                <span class="box-title">告警信息列表</span>
                <div class="top-btn">
                    <img alt src="../../assets/img/common/close.png"  @click="close"/>
                </div>
            </div>
            <div class="box-content">
                <div class="box-table">
                    <el-table :data="dataList" stripe style="width: 100%" highlight-current-row class="custom-table">
                        <el-table-column type="index" label="序号" width="50" align="center"/>
                        <el-table-column prop="warnTime" label="告警时间" width="130" align="center"/>
                        <el-table-column prop="warnContent" label="告警内容" width="330" :show-overflow-tooltip="true"/>
                        <el-table-column label="告警截图" width="80" align="center">
                            <template slot-scope="scope">
                                <!-- 告警截图、小视频的 类型值大于100 -->
                                <span class="btn" v-if="scope.row.warnType>100" @click="operate('lookup', scope.row)">查看</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="状态" width="80" align="center">
                            <template slot-scope="scope">
                                <span style="color: #b28501;" v-if="scope.row.handleState==0">待处理</span>
                                <span style="color: green;" v-else-if="scope.row.handleState==1">已处理</span>
                                <span style="color: orange;" v-else-if="scope.row.handleState==2">误报</span>
                                <span style="color: red;" v-else-if="scope.row.handleState==3">稍后处理</span>
                                <span style="color: red;" v-else>未知</span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="operator" label="处理人" width="80" align="center" :show-overflow-tooltip="true"/>
                        <el-table-column prop="handleRemark" label="备注" width="80" align="center" :show-overflow-tooltip="true"/>
                        <el-table-column label="操作" width="182" align="center">
                            <template slot-scope="scope">
                                <span class="btn" @click="operate('handle', scope.row)" title="处理">处理</span>
                                <span class="btn" @click="operate('detail', scope.row)" title="详情">详情</span>
                                <span class="btn" @click="operate('del', scope.row.id)" title="删除">删除</span>
                                <span class="btn" @click="showWarnTips(scope.row.warnContent)" title="显示告警">显示告警</span>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination background layout="total, prev, pager, next"
                        :total="total"
                        @current-change="handleCurrentChange"
                        style="text-align: center; margin-top: 10px; margin-bottom: 10px;">
                    </el-pagination>
                </div>

                <div>
                    <!-- 456 -->
                </div>

            </div>
        </div>

        <!--弹框操作-->
        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sure">
            <div class="box-content" v-if="currOperation=='del'">
                确认要删除此告警信息吗?
            </div>
            <div class="warn-detail" v-else-if="currOperation=='detail'">
                <ul>
                    <li>
                        <label>告警时间</label>
                        <span>{{ currWarn.warnTime }}</span>
                    </li>
                    <li>
                        <label>告警内容</label>
                        <span>{{ currWarn.warnContent }}</span>
                    </li>
                    <li>
                        <label>状态</label>
                        <span>{{ convertState(currWarn.handleState) }}</span>
                    </li>
                    <li>
                        <label>处理人</label>
                        <span>{{ currWarn.operator }}</span>
                    </li>
                    <li>
                        <label>处理时间</label>
                        <span>{{ currWarn.operateTime }}</span>
                    </li>
                    <li>
                        <label>处理备注</label>
                        <span>{{ currWarn.handleRemark }}</span>
                    </li>
                </ul>
            </div>
            <div class="warn-handle" v-else-if="currOperation=='handle'">
                <el-form ref="dgForm" :model="dgForm" label-width="80px">
                    <el-form-item label="告警内容">
                        <span>{{ dgForm.warnContent }}</span>
                    </el-form-item>
                    <el-form-item label="状态" prop="handleState">
                        <el-radio-group v-model="dgForm.handleState" @change="checkedHandleState">
                            <el-radio :label="1">已处理</el-radio>
                            <el-radio :label="2">误报</el-radio>
                            <el-radio :label="3">稍后处理</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="暂停告警">
                        <el-input-number size="mini" v-model="dgForm.pauseMinute" :min="0" :max="1440"></el-input-number>
                        <span>(分钟)</span>
                        <br/>
                        <span style="font-size: 12px; color: #ef5c4a;">设置暂停时长后，该船舶在指定时间内不再上报该类型的告警</span>
                    </el-form-item>
                    
                    <el-form-item label="备注">
                        <el-input v-model="dgForm.handleRemark" maxlength="20" show-word-limit/>
                    </el-form-item>
                </el-form>
            </div>
            <div class="warn-lookup" v-else-if="currOperation=='lookup'">
                <div class="warn-lookup-content">{{ currWarn.warnContent }}</div>
                <div class="warn-lookup-pic">
                    <el-image :src="warnImg" fit="scale-down" style="height: 400px;">
                        <div slot="error" class="image-slot" style="width: 700px; height: 400px; background-color: #f2f2f2; text-align: center; padding-top: 180px">
                            <i class="el-icon-picture-outline" style="font-size: 50px; color: gray;"></i>
                        </div>
                    </el-image>
                </div>
                <div class="btn-play" v-if="showBtnPlay">
                    <i class="el-icon-video-play" style="font-size: 80px; " @click="playVideo"></i>
                </div>
                <span class="btn-download" v-if="showBtnPlay" @click="downloadVideo">
                    下载视频文件
                </span>
            </div>
        </SswgModal>

        <SswgModal :visible.sync="modalVisibleVideo" modalTitle="播放视频文件">
            <EasyPlayer id="EasyPlayer" ref="easyPlayerRef" 
                :video-url="videoUrl"
                :live=false
                style="width: 890px; height: 500px;"
                >
            </EasyPlayer>
        </SswgModal>

    </div>
</template>

<script>
import EasyPlayer from '@easydarwin/easyplayer';
import { downloadFile } from '../../utils/sswgsz'
export default {
    inject: ['app'],
    components: {
        EasyPlayer
    },
    data() {
        return {
            dataList: [],
            total: 0,
            q: {
                agentId: '',
                currentPage: 1,
                pageSize: 10
            },
            centerDialogVisible: true,

            modalVisible: false,
            modalTitle: '',
            currOperation: '',             // del: 删除 detail:详情 handle:处理
            currWarnId: 0,
            openFooter: false,

            currWarn: {},
            warnImg: '',
            showBtnPlay: false,            // 是否显示播放视频按钮
            dgForm: {
                handleState: 0,
                pauseMinute: 0,
                handleRemark: '',
            },
            dgRules: {
                reason: [
                    { required: true, message: '必填项', trigger: 'blur' }
                ],
            },

            // 当前视频文件地址 
            currVideoUrl: '',
            // 实际播放的文件地址 http://127.0.0.1:9977/resource/video/20250113101808.mp4
            videoUrl: '', 
            modalVisibleVideo: false,

        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
        show(){
            return this.$store.state.showWarnList
        }
    },
    watch: {
        show(newVal, oldVal){
            if(newVal){
                this.resetForm()
                this.queryDataList()
            }
        }
    },
    mounted(){
        
    },
    methods: {

        // 下载视频文件
        downloadVideo(){
            this.$message.warning('下载中，请稍等...')
            let warnTime = this.parseTime(this.currWarn.warnTime, '{y}{m}{d}{h}{i}{s}')
            downloadFile(this.currVideoUrl, '告警视频'+warnTime).then(res=>{
                console.log(res)
                if(res.status==0){
                    // this.$message.success('下载成功')
                }else{
                    this.$message.error('下载视频文件出错')
                }
            })
        },

        // 播放视频文件
        playVideo(){
            this.videoUrl = this.currVideoUrl
            // this.videoUrl = 'http://127.0.0.1:9977/resource/video/video001.mp4'
            this.modalVisibleVideo = true
        },

        close(){
            this.$store.commit('updateShowWarnList', false)
        },

        queryDataList(){
            this.q.agentId = localStorage.getItem('login_agent_id')
            this.sswgPost('/warn/fetchWarnListPage', this.q).then(res => {
                this.dataList = res.data.records
                this.total = res.data.total
            }).catch(err => {
                this.$store.commit('logout')
            })
        },

        handleCurrentChange(val){
            this.q.currentPage = val
            this.queryDataList()
        },

        resetForm(){
            this.q.currentPage = 1
            this.q.pageSize = 10
        },

        convertState(state){
            let str = ''
            if(state==0){
                str = '待处理'
            }else if(state==1){
                str = '已处理'
            }else if(state==2){
                str = '误报'
            }else if(state==3){
                str = '稍后处理'
            }
            return str
        },

        checkedHandleState(v){
            console.log(v)
        },

        operate(oper, data){
            console.log(data)
            this.currOperation = oper
            this.modalVisible = true
            if(oper=='del'){
                this.modalTitle = '提示'
                this.openFooter = true
                this.currWarnId = data
            }else if(oper=='detail'){
                this.modalTitle = '详情'
                this.openFooter = false
                this.currWarn = data
            }else if(oper=='handle'){
                this.modalTitle = '处理'
                this.openFooter = true
                this.dgForm.id = data.id
                this.dgForm.warnContent = data.warnContent
                this.dgForm.handleRemark = data.handleRemark
                this.dgForm.handleState = data.handleState
                this.dgForm.pauseMinute = 0
            }else if(oper=='lookup'){
                this.showBtnPlay = false
                this.currVideoUrl = ''
                this.modalTitle = '告警截图'
                this.openFooter = false
                this.currWarn = data
                this.warnImg = this.baseApi + 'alarmImg/' + data.warnPicture
                if(data.warnVideo){
                    this.showBtnPlay = true
                    this.currVideoUrl = this.baseApi + 'alarmVideo/' + data.warnVideo
                }
            }
        },

        sure(){
            if(this.currOperation=='del'){
                let p = {
                    agentId: localStorage.getItem('login_agent_id'),
                    warnIds: []
                }
                p.warnIds.push(this.currWarnId)
                this.sswgPost('/warn/batchDeleteWarn', p).then(res => {
                    console.log(res)
                    if(res.code==200){
                        this.modalVisible = false
                        this.queryDataList()
                        this.$message.success('删除成功')
                    }else{
                        this.$message.error(res.message)
                    }
                    
                    
                })
            }else if(this.currOperation=='handle'){
                console.log(this.dgForm)
                let p = {
                    id: this.dgForm.id,
                    handleState: this.dgForm.handleState,
                    handleRemark: this.dgForm.handleRemark,
                    operator: localStorage.getItem('login_user_name'),
                    pauseMinute: this.dgForm.pauseMinute
                }
                this.sswgPost('/warn/updateWarnItemHandleState', p).then(res => {
                    if(res.code==200){
                        this.modalVisible = false
                        this.queryDataList()
                        this.$message.success('修改成功')
                    }else{
                        this.$message.error(res.message)
                    }
                })
            }
        },

        showWarnTips(msg){
            this.app.updateShowWarnMsg(msg)
        }
        
    }

}

</script>

<style lang="scss" scoped>
    .content {
        width: 100%;
        height: 100%; 
        position: fixed; 
        z-index: 190; 

        
        .mask {
            width: 100%;
            height: 100%;
            position: fixed;
            left: 0;
            top: 0;
            z-index: 180;
            opacity: 0.4;
            background-color: #74b1d1;
        }
        .box {
            position: fixed;
            left: 50%;
            top: 45%;
            // width: 280px;
            background-color: #0078d7;
            border-radius: 5px;
            transform: translate(-50%,-50%);
            border: 1px solid #5d6359;
            z-index: 210;
            color: #ffffff;
            .box-top {
                height: 32px;
                text-align: center;
                position: relative;
                .box-title {
                    font-weight: bold;
                    line-height: 30px;
                    font-size: 15px;
                    user-select: none;
                }
                .top-btn {
                    position: absolute;
                    right: 5px;
                    top: 5px;
                    user-select: none;
                    img {
                        cursor: pointer;
                    }
                }
            }
            .box-content {
                border: 1px solid #FFFFFF;
                border-radius: 0 0 4px 4px;
                padding: 4px 4px;
                .box-table {
                    border: 1px solid #000000;
                    background-color: #FFFFFF;
                }
            }
           
        }
    }

    .btn {
        color: #1762c3;
        border: 1px solid #0078d7;
        border-radius: 2px;
        padding: 1px 4px;
        background-color: #f0f0f0;
        cursor: pointer;
        font-size: 12px;
        margin-left: 4px;
        user-select: none;
    }
    .btn:hover {
        border: 1px dashed #0a0157;
    }

    .warn-detail {
        padding: 10px;
        ul {
            li {
                display: flex;
                padding: 5px 0;
                label {
                    width: 100px;
                }
                span {
                    width: 350px;
                }
            }
        }
    }

    .warn-handle {
        margin: 2px;
        padding-right: 10px;
        border: 1px solid rgb(180, 178, 178);
        width: 450px;
    }

    .warn-lookup {
        width: 700px;
        position: relative;
        .warn-lookup-content {
            margin: 10px 4px;
            padding: 2px;
            color: #FFFFFF;
            font-size: 16px;
            border: 1px dashed rgb(236, 44, 18);
        }
        .warn-lookup-pic {
            margin: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px dashed rgb(236, 44, 18);
            position: relative;
        }
        .btn-play {
            position: absolute;
            left: 45%;
            top: 45%;
            z-index: 10000;
            color: rgb(177, 176, 176);
            font-weight: bold;
            cursor: pointer;
            i:hover {
                color: #FFFFFF;
            }
        }
        .btn-download {
            position: absolute;
            right: 10px;
            top: 60px;
            color: #FFF;
            border: 1px dashed orange;
            padding: 2px 3px;
            border-radius: 4px;
            cursor: pointer;
        }
        .btn-download:hover {
            border-color: red;
        }

    }

</style>
<style scoped>

    #EasyPlayer >>> .icon-luxiang2 { display: none !important; }
    #EasyPlayer >>> .logo { display: none !important; }

    .custom-table >>> .cell {
        font-size: 12px;
        color: #000000;
        padding: 5px 0px;
    }

    .custom-table >>> th {
        background: url(../../assets/img/common/table_bg.png) left top / 100% 100% no-repeat #1832a8;
    }

    /** 表头样式 */
    .custom-table >>> th.el-table__cell>.cell {
        line-height: 22px;
        padding: 0 0;
        color: #FFFFFF;
        font-weight: 400;
    }

    .custom-table >>> .el-table__cell {
        padding: 0 0;
    }

    /* 修改表格行的鼠标悬停颜色 #86e9f9 */
    .custom-table >>> .el-table__body tr:hover>td.el-table__cell {
        background-color: #d4eaee !important;
    }

    /* 修改表格选中行颜色 */
    .custom-table >>> .el-table__body tr.current-row>td.el-table__cell {
        background-color: #3ebcf6 !important;
    }

    .warn-handle >>> .el-form-item__label {
        color: #FFFFFF;
    }

    .warn-handle >>> .el-radio {
        color: #FFFFFF;
    }

    .warn-handle >>> .el-form-item {
        margin-bottom: 0;
    }

    .warn-handle >>> .el-input__inner {
        height: 28px;
    }

    .warn-handle >>> .el-radio__input.is-checked+.el-radio__label {
        color: #eccf16;
    }



</style>