<template>
    <div class="content" v-if="showShipList">
        <div class="v1">
            <span class="title">船舶列表</span>
            <div class="p1">
                <img alt src="../../assets/img/common/close.png" @click="close"/>
            </div>
        </div>
        <div class="v2">
            <ul class="table-title" style="cursor: default">
                <li class="c1">序号</li>
                <li class="c2">名称</li>
                <li class="c3">MMSI</li>
                <li class="c4">设备ID</li>
            </ul>
            <ul v-for="(item, i) in dataList" :key="i" @click="checkedShip(item.id, item.outletsId, 1)" :style="{backgroundColor: currShipId==item.id?'#3ebcf6':'', color: !item.isOnline?'gray':''}">
                <li class="c1">{{ i+1 }}</li>
                <li class="c2" :style="{fontWeight: !item.isOnline?'none':'bold'}">{{ item.name }}</li>
                <li class="c3">{{ item.mmsi }}</li>
                <li class="c4">{{ item.positionDeviceId }}</li>
            </ul>
        </div>
        <div class="v3">
            <span>总数：{{total}}</span>
            <span>在线：{{onlineNum}}</span>
            <span>离线：{{offlineNum}}</span>
        </div>
    </div>
</template>

<script>

//引入自定义目标图层
import CustomTargetItem from '../../../public/hd/CustomTargetItem'
import { llPointToENPoint } from '@/utils'

// 船舶列表
export default {
    inject: ['instanceProvide', 'app'],
    data() {
        return {
            show: true,
            dataList: [],
            currShipId: 0,

            targetList: [],
            lastSelectedTarget: null,

            ws: null,                       // 船舶信息 websocket对象
            warnWebsocket: null,            // 摄像头告警 websocket对象


            // 海图自营船舶索引数据
            shipIdData: {},

            // 总数、在线、离线
            total: 0,
            onlineNum: 0,
            offlineNum: 0,

            // 上一次告警时间 秒
            lastWarnTime: 0,
        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
        showShipList(){
            return this.$store.state.showShipList
        }
    },
    watch: {
        hasLogin(newVal, oldVal){
            if(newVal){
                // 刷新设置信息
                this.app.querySettingInfo()

                this.queryShipList(1)
                this.connectWebSocket()         // 登录成功之后 创建连接
                this.createWarnWebSocket()      // 登录成功之后 创建告警websocket连接
            }else{
                this.dataList = []
                console.log('退出登录，开始断开websocket')
                this.checkAndCloseWebSocket()   // 退出登录之后 关闭连接
            }
        }
    },
    mounted(){
        console.log('mounted>>>>船舶组件 登录状态='+this.hasLogin)
        // F5刷新场景
        if(this.hasLogin){
            // 刷新设置信息
            this.app.querySettingInfo()

            this.queryShipList(2)
            this.connectWebSocket()
            this.createWarnWebSocket()
            
        }
    },
    methods: {

        // 测试websocket信息
        // keleTest(){
        //     let info = {
        //         "callNum": "0",
        //         "deviceSeq": "400040001",
        //         "deviceType": "ANTENNA",
        //         "elevation": 0,
        //         "humidity": 0,
        //         "id": 252,
        //         "lat": 36.98193,
        //         "lot": 125.97308,
        //         "messageType": "LOCATION",
        //         "mmsi": 0,
        //         "outletsId": "ff8080817ba105a4017ba56d06730003",
        //         "reportTime": "2024-10-25 11:54:58",
        //         "shipName": "海蓝鲸轮",
        //         "shipStatus": 1,
        //         "voyageAngle": "104.78",
        //         "voyageSpeed": "19.17",
        //         "warnSwitch": 1
        //     }
        //     this.updateShipInfoByWebsocketMsg(JSON.stringify(info))
        // },

        /**
         * 检查并关闭websocket （切换账号、退出登录时调用）
         */
        checkAndCloseWebSocket(){
            if(this.ws){
                this.ws.close()
            }
            if(this.warnWebsocket){
                this.warnWebsocket.close()
            }
        },

        // 更新警告提示
        updateWarnMsgTips(info){
            console.log(info)
            let data = JSON.parse(info)
            if(data.warnType>=0){
                if(!this.checkWarnDuration){
                    console.log('在告警频率内，本次告警不提示')
                    return
                }
                // 如果勾选了文本告警
                if(this.app.userConf.textWarnShow==0){
                    this.app.updateShowWarnMsg(data.warnContent, 1)
                }
            }
        },

        // 检查告警频率
        checkWarnDuration(){
            let b = true
            let currWarnTime = Math.floor(new Date().getTime()/1000)
            if(this.app.userConf.warnDuration){
                if(currWarnTime-this.lastWarnTime<Math.abs(this.app.userConf.warnDuration)){
                    return false
                }else{
                    this.lastWarnTime = currWarnTime
                }
            }
            return b
        },

        // 创建告警websocket对象
        createWarnWebSocket(){
            if(this.warnWebsocket){
                this.warnWebsocket.close()
            }
            // 新建一个连接
            const wsUrl = this.webSocketUrl+"/dps/alarmMessagePush?username=" + localStorage.getItem('login_user_name') + "&Authorization=" + 'SSWGSZ-VMS' + localStorage.getItem('vms_token')
            this.warnWebsocket = new WebSocket(wsUrl)
            this.warnWebsocket.onopen = () => {
                console.log('warn websocket connected')
            }
            this.warnWebsocket.onmessage = (msg) => {
                if(msg.data != 'HEATBEAT_CONFIRM'){
                    this.updateWarnMsgTips(msg.data)
                }else{
                    // 测试
                    // let kk = {
                    //     warnType: 0,
                    //     warnContent: '我是测试告警内容'
                    // }
                    // this.updateWarnMsgTips(JSON.stringify(kk))
                }
            }
            this.warnWebsocket.onerror = (error) => {
                console.log('warn websocket 错误>>>>'+JSON.stringify(error))
            }
            // 保活
            let warnInterval = setInterval(()=>{
                if(this.warnWebsocket.readyState === WebSocket.OPEN){
                    this.warnWebsocket.send('HEATBEAT')
                }
            }, 30000)
            this.warnWebsocket.onclose = () => {
                console.log('warn websocket 关闭连接 ' +new Date())
                clearInterval(warnInterval)
            }
        },

        // 连接船舶位置websocket
        connectWebSocket() {
            if(this.ws){
                this.ws.close()
            }
            // 新建一个连接
            const wsUrl = this.webSocketUrl+"/dps/messagePush?username=" + localStorage.getItem('login_user_name') + "&Authorization=" + 'SSWGSZ-VMS' + localStorage.getItem('vms_token')
            this.ws = new WebSocket(wsUrl)

            this.ws.onopen = () => {
                console.log('websocket connected')
            }

            this.ws.onmessage = (msg) => {
                // console.log('websocket 收到消息>>>>'+new Date())
                // console.log(msg)
                if(msg.data != 'HEATBEAT_CONFIRM'){
                    this.updateShipInfoByWebsocketMsg(msg.data)
                }else{
                    
                }
            }

            this.ws.onerror = (error) => {
                console.log('websocket 错误>>>>'+JSON.stringify(error))
            }

            // 保活
            let shipInterval = setInterval(()=>{
                if(this.ws.readyState === WebSocket.OPEN){
                    this.ws.send('HEATBEAT')
                }
            }, 30000)

            this.ws.onclose = () => {
                console.log('websocket 关闭连接 '+new Date())
                clearInterval(shipInterval)
            }
        },

        /**
         * 根据websocket数据更新船舶信息
         * @param info 船舶信息
         */
        updateShipInfoByWebsocketMsg(info){
            let data = JSON.parse(info)
            // 船舶索引
            const idIndex = this.app.shipIdData['shipId'+data.id]
            const center = llPointToENPoint(data.lot, data.lat)
            const text = this.convertShipText(data.shipName, data.mmsi, data.callNum, data.voyageSpeed)
            const cog = Number(data.voyageAngle)    // 航向
            const sog = Number(data.voyageSpeed)    // 航速
            this.instanceProvide.itemLayer.items()[idIndex].updateShipInfoByWebSocketData(center, text, cog, sog)
            // 如果是当前船舶，则也更新显示信息
            if(data.id==this.currShipId){
                this.app.updateShipInfoByWebsocketData(data.lot, data.lat, cog, sog, data.reportTime)
            }
        },

        close() {
            this.$store.commit('updateShowShipList', false)
        },

        /**
         * 查询船舶列表
         * @param src 来源 1:登录 2:F5刷新页面
         */
        queryShipList(src){
            console.log('查询船舶列表 '+src)
            this.sswgPost('/outlets/shiplist', {}).then(res => {
                console.log(res)
                this.total = res.data.total
                this.onlineNum = res.data.noOfOnline
                this.offlineNum = res.data.noOfOffline
                // 过滤字段
                this.dataList = res.data.shipList.map(({id, outletsId, name, mmsi, positionDeviceId, lat, lot, imo, speed, callNum, isOnline}) => ({id, outletsId, name, mmsi, positionDeviceId, lat, lot, imo, speed, callNum, isOnline}));

                this.app.shipList = this.dataList
            
                // 添加到海图上
                if(src==2){
                    setTimeout(() => {
                        this.drawTarget()
                    }, 3000)
                }else{
                    this.drawTarget()
                }
                
            }).catch(err => {
                console.log(err)
                // 网络出错，一般是后台token失效，故直接退出登录，引导用户重新登录
                this.$store.commit('logout')
            })
        },

        // 添加船舶到海图
        async drawTarget() {
            // 清除原来的物标，刷新页面时为null
            if(this.instanceProvide.itemLayer){ 
                this.instanceProvide.itemLayer.clearItems()
            }

            let shipIdIndex = 0 // 船舶索引
            for(let i=0; i<this.dataList.length; i++){
                let ship = this.dataList[i]
                if(ship.lot){
                    // console.log('我是第+'+i)
                    // console.log(ship)
                    // 记录船舶所在的索引
                    this.app.shipIdData['shipId'+ship.id] = shipIdIndex
                    shipIdIndex++
                    const point = new CustomTargetItem({
                        center: llPointToENPoint(ship.lot, ship.lat),
                        text: this.convertShipText(ship.name, ship.mmsi, ship.callNum, ship.speed),    // 船名+MMSI+呼号+速度 
                        shipScale: 10,                  //三角船显示层级
                        titleScale: 8,                  //船名显示层级
                        cog: -320,                      //航向
                        sog: 20,                        //航速
                        hdg: 10,                        //船艏向
                        fillType: 1,                    //船只填充类型
                        fillColor: '#2ECC71',           //船只填充颜色
                        strokeColor: '#000000',         //船只边框颜色
                        strokeType: 1,                  //船只边框类型
                        strokeWeight: 1,                //船只边框宽度
                        textColor: '000000',            //文本颜色
                        labelFillColor: '#d4eaee',      //Label填充颜色 #d4eaee
                        labelFillType: 1,               //Label填充类型
                        fontSize: 10,                   //文字大小
                    })

                    point.setShipIdAndMerchantId(ship.id, ship.outletsId)   // 补充参数 船ID和店铺ID

                    point.setEnableMouseTracking(true)      // 使hover效果生效
                    this.instanceProvide.itemLayer.addItem(point)

                    point.on('doubleclick', (item, event) => {
                        console.log(item, event);
                    })

                    //pressed 鼠标按下
                    point.on('pressed', (item, event) => {
                        //编辑状态(启用之后，可以移动点)
                        // item.setState(SceneItemCommon.ItemState.editing)

                        //pressed 鼠标抬起
                        point.on('release', (item, event) => {
                            //结束编辑状态
                            // item.setState(SceneItemCommon.ItemState.normal)

                            // 如果有上一个，则清除上一个的选择状态
                            if (this.lastSelectedTarget) {
                                this.lastSelectedTarget.setSelected(false)
                            }
                            item.setSelected(true)
                            this.lastSelectedTarget = item
                            item.setOptions({
                                fillColor: '#ffff00',
                                fillType: 1,
                                strokeColor: '#ff0000'
                            })
                           
                        })
                        console.log(item)
                        // item.setZIndex(10000)
                        // 选中船舶
                        this.checkedShip(item.shipId, item.merchantId, 2)
                    })

                    point.on('hoverenter', (item, event) => {
                        // console.log(22222);
                        item.setOptions({
                            fillColor: '#ffff00',
                            fillType: 1,
                            strokeColor: '#ff0000'
                        })
                    })

                    point.on('hoverleave', (item, event) => {
                        item.setOptions({
                            fillColor: '#2ECC71',
                            fillType: 1,
                            strokeColor: '#000000'
                        })
                    })

                    this.targetList.push(point)
                }
            }
            // this.instanceProvide.itemLayer.update() // 更新
        }, 

        /**
         * 转换船舶提示文本     
         * @param name      船名    必填
         * @param mmsi      MMSI    
         * @param callNum   呼号
         * @param speed     航速
         */
        convertShipText(name, mmsi, callNum, speed){
            let text = name
            const userConf = this.app.userConf
            if(mmsi!=null && userConf.mmsiShow==1){
                text += '\n'+mmsi
            }
            if(callNum!=null && callNum!='0' && userConf.callNumShow==1){
                text += '\n'+callNum
            }
            if(speed!=null && userConf.speedShow==1){
                text += '\n'+speed+'Kn'
            }
            return text
        },

        // 查询船舶信息 outletsId:店铺ID
        queryShipDetail(id, outletsId, src){
            this.currShipId = id
            this.sswgPost('/outlets/fetchShipDetail', {outletsId: outletsId}).then(res => {
                console.log(res)

                this.$emit('change', res.data, src)
                this.$store.commit('updateShowShipDetail', true)

            }).catch(err => {
                console.log(err)
                // 网络出错，一般是后台token失效，故直接退出登录，引导用户重新登录
                this.$store.commit('logout')
            })
        },

        /**
         * 点击选中某一艘船
         * @param id            船id
         * @param outletsId     店铺id
         * @param src           来源 1:点击船舶列表中的船舶 2:点击海图上面的船舶(不需要居中定位)
         */
        checkedShip(id, outletsId, src){
            this.queryShipDetail(id, outletsId, src)
        }
    }

}

</script>

<style lang="scss" scoped>
    .content {
        width: 360px;
        height: calc(100% - 118px);
        background-color: #1686d8;
        border: 1px solid #000;
        border-radius: 5px;
        // margin-left: 1px;

        .v1 {
            // width: 100%;
            width: 360px;
            height: 30px;
            // background-color: red;

            text-align: center;
            position: relative;
            .title {
                color: #FFF;
                font-weight: bold;
                line-height: 30px;
                font-size: 15px;
            }
            .p1 {
                // width: 100px;
                // height: 50px;
                background-color: #1686d8;
                position: absolute;
                right: 5px;
                top: 5px;
                // background-color: antiquewhite;
                // img {
                //     position: absolute;
                //     right: 10px;
                // }
                img {
                    cursor: pointer;
                }
            }
        }

        .v2 {
            background-color: #e5efff;
            // width: 100%;
            height: calc(100% - 60px);
            // height: 300px;
            overflow-y: auto;
            border-bottom: 1px solid #000000;
            ul {
                display: flex;
                text-align: center;
                line-height: 26px;
                font-size: 14px;
                color: #000;
                .c1 {
                    width: 50px;
                }
                .c2 {
                    width: 126px;
                }
                .c3 {
                    width: 90px;
                }
                .c4 {
                    width: 90px;
                }
            }
            ul:hover {
                background-color: #86e9f9;
                cursor: pointer;
            }
            .table-title {
                background: url(../../assets/img/common/table_bg.png) left top / 100% 100% no-repeat #1832a8;
                color: #FFF;
                font-size: 12px;
                line-height: 16px;
                user-select: none;
            }
        }
        .v3 {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 30px;
            padding: 0 10px;
            color: #FFFFFF;
        }

        .online {
            height: 5px;
            height: 5px;
            background-color: #00FF00;
            border-radius: 50%;
            display: inline-block;
        }
    }
</style>