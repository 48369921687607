<template>
    <div class="content">
        <div class="nav">
            <ul>
                <li class="navli" v-for="(item, index) in menuList" @mouseenter="mouseenterMenu(1, item.perm)">
                    <span>{{ item.title }}</span>
                    <ul v-if="item.perm==currMenuLv1Perm" @mouseleave="mouseleaveMenu()">
                        <li class="arrow" v-for="(item2, index2) in item.children" @click="clickMenu2(item2.perm)">
                            <span>{{ item2.title }}</span>
                        </li>
                    </ul>
                </li>
            </ul>
            
            <div class="login">
                <span class="login-name" v-if="hasLogin">{{ loginName }}</span>
                <span class="btn login-out" v-if="hasLogin" @click="logout">注销</span>
                <span class="btn" v-else @click="login">登录</span>
            </div>

        </div>

        <Login></Login>
        
        <!--弹框操作-->
        <SswgModal :visible.sync="modalVisible" :modalTitle="modalTitle" :openFooter="openFooter" @sure="sure">
            <div class="box-content" v-if="currOperation=='del'">
                确认要删除此告警信息吗?
            </div>
            <!-- <div class="warn-detail" v-else-if="currOperation=='detail'">
                <ul>
                    <li>
                        <label>处理备注</label>
                        <span>{{ currWarn.handleRemark }}</span>
                    </li>
                </ul>
            </div> -->
            <div class="warn-handle" v-else-if="currOperation=='ship_info'">
                <el-form ref="dgForm" :model="dgForm" label-width="80px">
                    <el-form-item label="船名">
                        <el-radio-group v-model="dgForm.shipNameShow">
                            <el-radio :label="0" disabled>隐藏</el-radio>
                            <el-radio :label="1">显示</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="MMSI">
                        <el-radio-group v-model="dgForm.mmsiShow">
                            <el-radio :label="0">隐藏</el-radio>
                            <el-radio :label="1">显示</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="呼号">
                        <el-radio-group v-model="dgForm.callNumShow">
                            <el-radio :label="0">隐藏</el-radio>
                            <el-radio :label="1">显示</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="航速">
                        <el-radio-group v-model="dgForm.speedShow">
                            <el-radio :label="0">隐藏</el-radio>
                            <el-radio :label="1">显示</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-form>
            </div>
            <div class="about" v-else-if="currOperation=='about'">
                <span>售后服务电话： 0755-26807666</span>
                <span>软件版本号： v2.0</span>
                <span>深圳市天海世界卫星应用科技有限公司</span>
                <span>版权所有 (C) 2022</span>
            </div>


        </SswgModal>



    </div>
</template>

<script>
import Login from '../login/Login.vue';
export default {
    inject: ['app'],
    components: {
        Login,
    },
    provide() {
        return {
            app: this, // 向下层组件传递所需图层实例
        }
    },
    data() {
        return {
            // loginName: localStorage.getItem('login_user_name'),
            modalVisible: false,
            modalTitle: '',
            currOperation: '',             // del: 删除 detail:详情 handle:处理
            currWarnId: 0,
            openFooter: true,

            dgForm: {
                shipNameShow: 1,
                mmsiShow: 0,
                callNumShow: 0,
                speedShow: 0
            },


            // 当前一、二级菜单标识
            currMenuLv1Perm: '',
            currMenuLv2Perm: '',
            menuList: [
                {
                    title: '系统设置',
                    perm: 'sys_setting',
                    children: [
                        {
                            title: '经纬度格式',
                            perm: 'lat_format',
                            children: [
                                {
                                    title: '度',
                                    perm: '',
                                },
                                {
                                    title: '度分',
                                    perm: '',
                                },
                                {
                                    title: '度分秒',
                                    perm: '',
                                }
                            ]
                        },
                    ]
                },
                {
                    title: '船舶显示设置',
                    perm: 'ship_setting',
                    children: [
                        {
                            title: '船舶信息显示设置',
                            perm: 'ship_info'
                        },
                    ]
                },
                {
                    title: '帮助',
                    perm: 'help',
                    children: [
                        {
                            title: '关于我们',
                            perm: 'about'
                        },
                    ]
                }
            ],

        }
    },
    computed: {
        hasLogin(){
            return this.$store.state.hasLogin
        },
        loginName(){
            return this.$store.state.loginUserName
        }
    },
    mounted(){

    },
    methods: {
        // 鼠标移动到li触发的事件
        mouseenterMenu(lv, perm){
            // console.log(perm)
            if(lv==1){
                this.currMenuLv1Perm = perm
                this.currMenuLv2Perm = ''
            }else if(lv==2){
                this.currMenuLv2Perm = perm
            }
            
        },

        clickMenu2(k){
            if(k=='ship_info'){
                this.currOperation = 'ship_info'
                this.modalTitle = '船舶信息显示设置'
                this.modalVisible = true
                this.dgForm.mmsiShow = this.app.userConf.mmsiShow
                this.dgForm.callNumShow = this.app.userConf.callNumShow
                this.dgForm.speedShow = this.app.userConf.speedShow
            }else if(k=='about'){
                this.currOperation = 'about'
                this.modalTitle = '关于我们'
                this.modalVisible = true
                this.openFooter= false
            }else{
                this.$notify({
                    title: '功能模块待完善。。',
                    position: 'top-left',
                    duration: 5000,
                    type: 'warning',
                    offset: 100
                });
            }
        },

        sure(){
            if(this.currOperation=='ship_info'){    // 船舶信息显示
                let p = {
                    username: localStorage.getItem('login_user_name'),
                    shipNameShow: this.dgForm.shipNameShow,
                    mmsiShow: this.dgForm.mmsiShow,
                    callNumShow: this.dgForm.callNumShow,
                    speedShow: this.dgForm.speedShow
                }
                this.sswgPost('/displaySetting/updateUserDisplaySettingInfo', p, true, 'json').then(res => {
                    if(res && res.code==200){
                        this.$message({ message: '设置成功', type: 'success' })
                        this.modalVisible = false
                        // 刷新设置信息
                        this.app.querySettingInfo()
                        // 刷新船舶信息
                        this.app.refreshShipList()
                    }
                })
            }
        },



        // 鼠标离开第三级菜单，隐藏二三级菜单
        mouseleaveMenu(){
            this.currMenuLv1Perm = '-'
        },

        login(){
            this.logout()
        },

        // 注销
        logout(){
            this.$store.commit('logout')
        }

    }

}

</script>

<style lang="scss" scoped>
    .content {
        display: flex;
    }

    .nav {width: 100%; height: 28px; background-color: #1686d8; position: relative; z-index: 100;}
    .nav>ul {width: max-content;}
    .nav>ul>li {float: left; padding: 0 5px; height: 28px; line-height: 28px; position: relative; cursor: pointer;}
    .nav>ul>li>span {font-size: 12px; color: #fff;}
    .nav>ul>li:hover {background: #86e9f9;}
    .nav>ul>li:hover>span {color: #000;}

    .navli>ul {border: 3px solid #034da5;background:url(../../assets/img/nav/bg.png) left top / 160px 100% no-repeat #034da5;position: absolute;left: 0;top: 28px;border-radius: 5px; width: max-content;}
    .navli>ul li{font-size: 12px; color: #fff; user-select: none;}
    .navli li {display: flex; position: relative; padding-left:30px; padding-right: 60px;}
    .navli li:hover{background:linear-gradient(to right,#31b6f7,#034da5);}

    // .navli .arrow {background:url(../../assets/img/nav/arrow.png) 96% center / auto 8px no-repeat;}
    // .navli li.arrow:hover{background: url(../../assets/img/nav/arrow.png) 96% center / auto 8px no-repeat, linear-gradient(to right,#31b6f7,#034da5);}
    .navli ul li ul{border: 3px solid #034da5;background:url(../../assets/img/nav/bg.png) left top / 160px 100%  no-repeat #034da5;position: absolute;left:100%;top: 0;border-radius: 5px;width: max-content;} // display: none;
    .navli ul li ul li{padding-right: 0;}
    .navli ul li ul span{height: 28px;display: block;padding-right: 60px;width: 100%;}

    .login {
        color: #FFF;
        float: right;
        margin-right: 20px;
        margin-top: 4px;
        .btn {
            padding: 3px 14px;
            width: max-content;
            background-color: #1ead15;
            font-size: 12px;
            border-radius: 3px;
            cursor: pointer;
            box-shadow: inset -1px -1px 1px #3b3b3b;
            user-select: none;
        }
        .btn:active {
            box-shadow: inset -1px -1px 1px #020202;
        }
        .login-name {
            padding: 0 10px;
        }
        .login-out {
            background-color: rgb(206, 26, 26);
        }
    }

    .about {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 15px;
        span {
            height: 2rem;
        }
    }
</style>

<style scoped>

    .custom-table >>> .cell {
        font-size: 12px;
        color: #000000;
        padding: 5px 0px;
    }

    .custom-table >>> th {
        background: url(../../assets/img/common/table_bg.png) left top / 100% 100% no-repeat #1832a8;
    }

    /** 表头样式 */
    .custom-table >>> th.el-table__cell>.cell {
        line-height: 22px;
        padding: 0 0;
        color: #FFFFFF;
        font-weight: 400;
    }

    .custom-table >>> .el-table__cell {
        padding: 0 0;
    }

    /* 修改表格行的鼠标悬停颜色 */
    .custom-table >>> .el-table__body tr:hover>td.el-table__cell {
        background-color: #86e9f9 !important;
    }

    /* 修改表格选中行颜色 */
    .custom-table >>> .el-table__body tr.current-row>td.el-table__cell {
        background-color: #3ebcf6 !important;
    }

    .warn-handle >>> .el-form-item__label {
        color: #FFFFFF;
    }

    .warn-handle >>> .el-radio {
        color: #FFFFFF;
    }

    .warn-handle >>> .el-form-item {
        margin-bottom: 0;
    }

    .warn-handle >>> .el-input__inner {
        height: 28px;
    }

    .warn-handle >>> .el-radio__input.is-checked+.el-radio__label {
        color: #eccf16;
    }



</style>