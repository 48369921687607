import { baseApi } from './config'

import axios from 'axios'
import { Message } from 'element-ui';


// axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded'

// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: baseApi,
  // 超时
  timeout: 10000
})

// request拦截器
service.interceptors.request.use(config => {
    // 默认需要认证登录
    if(config.authLogin==undefined || config.authLogin==true){
        config.headers.authorization = 'SSWGSZ-VMS' + localStorage.getItem('vms_token')
    }
    if(config.contentType && config.contentType=='json'){
        config.headers['Content-Type'] = 'application/json;charset=utf-8'
    }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
        // console.log(888888888888888)
        // console.log(res)

        // 未设置状态码则默认成功状态
        const status = res.status;
        if(status == 200) {
            return res.data
        } else {
            return res.data
        }
        
    },
    error => {
        console.log('error>>>')
        console.log(error)
        let { message } = error;
        if (message == "Network Error") {
            // message = "后端接口连接异常";
            message = "登录失效，请重新登录"
            localStorage.removeItem('hasLogin')
            localStorage.removeItem('vms_token')
        } else if (message.includes("timeout")) {
            message = "系统接口请求超时";
        } else if (message.includes("Request failed with status code")) {
            message = "系统接口" + message.substr(message.length - 3) + "异常";
        }
        Message({ message: message, type: 'error', duration: 5 * 1000 })

        return Promise.reject(error)
    }
)

export default service