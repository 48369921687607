<template>
    <div class="content" v-if="show">
        <div class="v1">
            <ul>
                <li>
                    <label>MMSI</label>
                    <span>{{ ship.mmsi }}</span>
                </li>
                <li>
                    <label>呼号</label>
                    <span>{{ ship.callNum }}</span>
                </li>
                <li>
                    <label>经度</label>
                    <span>{{ ship.lon }}</span>
                </li>
                <li>
                    <label>船长</label>
                    <span>{{ ship.shipLength }}</span>
                </li>
                <li>
                    <label>航向</label>
                    <span>{{ ship.courseOverGround }}</span>
                </li>
                <li>
                    <label>吃水</label>
                    <span>{{ ship.draught }}</span>
                </li>
            </ul>
            <ul class="u2">
                <li>
                    <label>船名</label>
                    <span>{{ ship.name }}</span>
                </li>
                <li>
                    <label>距离</label>
                    <span>{{ ship.distance }}</span>
                </li>
                <li>
                    <label>纬度</label>
                    <span>{{ ship.lat }}</span>
                </li>
                <li>
                    <label>船宽</label>
                    <span>{{ ship.shipWidth }}</span>
                </li>
                <li>
                    <label>航速</label>
                    <span>{{ ship.speedOverGround }}</span>
                </li>
                <li>
                    <label>更新时间</label>
                    <span>{{ ship.updateTime }}</span>
                </li>
            </ul>
            
        </div>
        <span class="close-btn" @click="close">X</span>
    </div>
</template>

<script>
import { lonToDegreeMinString, latToDegreeMinString } from '@/utils';

const lebalTargetItem = (item) => {
    return {
        mmsi: item.mmsi,
        name: item.name || '--',
        callNum: (!item.callNum || item.callNum=='0') ? '--' : item.callNum,
        // distance: item.distance? `${item.distance}km` : '--', 全局AIS不显示距离
        distance: '--',
        lon: item.lon ? lonToDegreeMinString(item.lon, 4) : '--',
        lat: item.lat ? latToDegreeMinString(item.lat, 4) : '--',
        shipLength: item.shipLength ? `${item.shipLength}m` : '--',
        shipWidth: item.shipWidth ? `${item.shipWidth}m` : '--',
        courseOverGround: item.courseOverGround==null ? '--' : item.courseOverGround+'°',
        speedOverGround: item.speedOverGround==null ? '--' : item.speedOverGround+'Kn',
        draught: item.draught ? `${item.draught}m` : '--',
        updateTime: item.updateTime || '--',
    }
}

export default {
    inject: ['parent'],
    data() {
        return {
            show: false,
            shipInfo: {}
        }
    },
    computed: {
        ship(){
            return lebalTargetItem(this.shipInfo)
        },
    },
    
    methods: {
        close(){
            this.show = false
            this.parent.clearSelected()
        },
        setShow(){
            this.show = true
        }
    }
}
</script>

<style lang="scss" scoped>
    .content {
        width: 435px;

        position: absolute;
        right: 10px;
        top: 10px;

        background-color: rgba(3, 76, 165, 0.81);
        border-radius: 5px;
        border: 2px solid #FFF;

        .close-btn {
            position: absolute;
            top: -8px;
            right: -10px;
            background-color: #27619f;
            width: 20px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border-radius: 50%;
            color: #ebeaea;
            cursor: pointer;
            border: 2px solid #FFF;
            font-size: 12px;
        }

        .v1 {
            padding: 10px;
            display: flex;
            justify-content: space-between;
            ul {
                li {
                    font-size: 13px;
                    color: #FFF;
                    display: flex;
                    align-items: center;
                    margin-top: 4px;
                    label {
                        width: 60px;
                    }
                    span {
                        width: 130px;
                        height: 22px;
                        line-height: 22px;
                        border: 1px solid #bdbcbc;
                        border-radius: 4px;
                        text-indent: 5px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        display: block;
                    }
                }
            }

            .u2 {
                li {
                    span {
                        width: 140px;
                    }
                    .w80 {
                        width: 80px;
                    }
                }
            }
        }
    }
</style>